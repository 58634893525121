import React from 'react'
import { Helmet } from 'react-helmet'

const SchemaWebsite = () => {
  const structuredDataWebsite = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: process.env.SITE_BASE_URL,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${process.env.SITE_BASE_URL}/kereso?q={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredDataWebsite, null, 2)}
      </script>
    </Helmet>
  )
}

export default SchemaWebsite
