import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import useSocialUrls from '../../hooks/useSocialUrls'

const SchemaOrganization = () => {
  const {
    facebook, youTube, instagram, linkedIn,
  } = useSocialUrls()
  const data = useStaticQuery(graphql`
    {
      wp {
       seo {
        schema {
          companyName
          companyLogo {
            sourceUrl
          }
        }
       }
      }
    }
  `)

  const { schema } = data.wp.seo

  const organisationData = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    legalName: schema.companyName,
    url: process.env.SITE_BASE_URL,
    logo: schema.companyLogo.sourceUrl,
    contactPoint: [{
      '@type': 'ContactPoint',
      telephone: '+1-646-350-2789',
      contactType: 'customer service',
    }],
    sameAs: [
      facebook.url,
      instagram.url,
      youTube.url,
      linkedIn.url,
    ].filter((v) => !!v),
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(organisationData, null, 2)}
      </script>
    </Helmet>
  )
}

export default SchemaOrganization
